import { useField } from 'formik';
import styled, { css } from 'styled-components';

import { TeamRole } from '../../../../../../../typings/TeamMember.interface';
import { usePermissionAwareDisabled } from '../../../contexts/TeamPermissionContext';
import { StyledUtilsProps } from '../../helpers/StyledUtils';

interface Props extends StyledUtilsProps {
  name: string;
  show_label?: boolean;
  disabled?: boolean;
  role?: TeamRole;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const StyledSwitch = styled.label<{ disabled?: boolean; has_label: boolean }>(
  ({ theme, disabled, has_label }) => css`
    display: flex;
    font-weight: ${theme.font_weigths.regular};
    margin: 0;

    ${!has_label &&
    css`
      margin: 0;
    `}
    input {
      ${has_label &&
      css`
        margin-left: ${theme.spacing(2)};
      `}

      appearance: none;
      width: ${theme.pxToRem(36)};
      height: ${theme.pxToRem(20)};
      border-radius: ${theme.radius.small};
      transform: translateY(0);
      background-color: ${theme.colors.surface.container.neutral};
      display: grid;
      place-content: center;
      position: relative;

      &:hover:not(:disabled) {
        cursor: pointer;
      }

      &:checked:not(:disabled) {
        border-color: ${theme.colors.surface.base.primary};
        background-color: ${theme.colors.surface.base.primary};
      }

      &:disabled {
        background-color: ${theme.colors.surface.base.disabled};
        color: ${theme.colors.on.neutral.disabled};
        cursor: not-allowed;
      }

      &:disabled + span {
        color: ${theme.colors.on.neutral.secondary_neutral};
      }

      &::after {
        content: '';
        position: absolute;
        right: 3px;
        left: 3px;
        top: 3px;
        bottom: 3px;
        height: ${theme.pxToRem(12)};
        width: ${theme.pxToRem(26)};
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        background-image: ${`url("data:image/svg+xml,%3Csvg width='26' height='12' viewBox='0 0 26 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 2C0 0.895431 0.895431 0 2 0H10C11.1046 0 12 0.895431 12 2V10C12 11.1046 11.1046 12 10 12H2C0.895431 12 0 11.1046 0 10V2Z' fill='${theme.colors.on.hue.primary.replace(
          '#',
          '%23',
        )}'/%3E%3Ccircle cx='21' cy='6' r='3.5' stroke='${(disabled
          ? theme.colors.on.neutral.disabled
          : theme.colors.on.neutral.primary_neutral
        ).replace('#', '%23')}' stroke-linecap='round'/%3E%3C/svg%3E%0A")`};
      }

      &:checked {
        &::after {
          left: 5px;
          right: unset;
          background-image: ${`url("data:image/svg+xml,%3Csvg width='28' height='12' viewBox='0 0 26 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 2C14 0.895431 14.8954 0 16 0H24C25.1046 0 26 0.895431 26 2V10C26 11.1046 25.1046 12 24 12H16C14.8954 12 14 11.1046 14 10V2Z' fill='${theme.colors.on.hue.primary.replace(
            '#',
            '%23',
          )}'/%3E%3Cpath d='M4 2V10' stroke='${theme.colors.on.hue.primary.replace(
            '#',
            '%23',
          )}'/%3E%3C/svg%3E%0A")`};
        }
      }
      &:focus {
        outline: none;
      }
      &:focus-visible {
        &::before {
          content: '';
          position: absolute;
          top: -4px;
          left: -4px;
          right: -4px;
          bottom: -4px;
          border: 2px solid ${theme.colors.outline.focus.primary};
          border-radius: ${theme.radius.large_inset};
        }
      }
    }

    ${disabled &&
    css`
      span:first-of-type {
        color: ${theme.colors.on.neutral.secondary_neutral};
      }
    `}
  `,
);

const SwitchInput: React.FC<Props> = ({ show_label, name, role, onChange, ...props }) => {
  const [field] = useField(name);
  const disabled = usePermissionAwareDisabled(props.disabled, role);

  return (
    <StyledSwitch as="label" has_label={!!show_label} {...props}>
      {show_label && (field.value ? 'On' : 'Off')}
      <input
        {...field}
        checked={!!field.value}
        disabled={disabled || props.disabled}
        onChange={(e) => {
          field.onChange(e);
          onChange?.(e);
        }}
        type="checkbox"
      />
    </StyledSwitch>
  );
};

export default SwitchInput;
