import { FC, PropsWithChildren, useContext, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';

import LINKS from '../../../../configs/links';
import Button from '../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import Text from '../../../common/base/Text';
import { DashboardContext } from '../DashboardContext';

const StyledPaywallWrapper = styled.div`
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: absolute;

  img {
    width: 100%;
    height: auto;
    margin-top: ${({ theme }) => theme.spacing(2)};
    aspect-ratio: 1344/752;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.surface.base.backdrop};
    z-index: ${({ theme }) => theme.zindex.sticky};
  }
`;

const StyledUpgradeCard = styled(StyledCard)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${({ theme }) => theme.zindex.modal};
  max-width: 704px;
  box-shadow: ${({ theme }) => theme.elevation[3]};
`;

export const useMetricsEnabled = () => {
  const { subscription } = useContext(DashboardContext);

  return subscription?.features?.includes('metrics') ?? false;
};

const MetricsPaywall: FC<PropsWithChildren<{}>> = ({ children }) => {
  const theme = useTheme();
  const enabled = useMetricsEnabled();
  const eligible = !enabled;

  useEffect(() => {
    if (eligible) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'visible';
      };
    }
  }, [eligible]);

  if (eligible) {
    return (
      <StyledPaywallWrapper>
        <StyledUpgradeCard>
          <StyledCardSection p={4}>
            <Text bold size="l" as="h1" m={0}>
              Enable metrics
            </Text>
          </StyledCardSection>
          <StyledCardSection p={4}>
            <Text as="p">
              Monitor your events rate, queued depth, oldest event, and other key trends with
              Metrics. Segment data by time range, source, and destination, and export your metrics
              to Datadog and other observability tools.
            </Text>
            <img height="" src={`/images/metrics-upgrade-${theme.mode}.png`} />
          </StyledCardSection>
          <StyledCardSection p={4} flex={{ justify: 'space-between' }}>
            <Button as="a" neutral icon="link" target="_blank" href={LINKS.product_docs.metrics}>
              Learn about metrics
            </Button>
            <Button primary icon="upgrade" to={'/settings/organization/plans?highlight=metrics'}>
              Upgrade to Growth Plan
            </Button>
          </StyledCardSection>
        </StyledUpgradeCard>
        {children}
      </StyledPaywallWrapper>
    );
  }
  return <>{children}</>;
};

export default MetricsPaywall;
